<template>
  <v-data-table
    :items="drug.adverse_reactions"
    :headers="[
      { text: 'Adverse Reaction', value: 'text' },
      { text: $t('Severity'), value: 'details.severity' },
      { text: 'Incidence', value: 'details.incidence' },
      { text: $t('Timing'), value: 'details.timing' },
      // { text: 'Management', value: 'interaction.management' },
      { value: 'actions', align: 'end', sortable: false },
    ]"
    no-data-text="No adverse reactions"
    sort-by="text.en"
    fixed-header
  >
    <template #top>
      <div class="d-flex justify-space-between">
        <h5 class="text-overline">
          Adverse Reactions
        </h5>
        <v-spacer />
        <v-combobox
          v-model="drug.adverse_reactions"
          dense
          :items="adverse_reactions"
          menu-props="closeOnClick, closeOnContentClick"
          multiple
          no-display
          no-hint
          prepend-inner-icon="fa fa-plus-circle success--text"
          small-chips
          outlined
          return-object
          hide-selected
          item-text="text.en"
          @change="onChange"
        >
          <template #selection />
        </v-combobox>
      </div>
    </template>
    <template #item.details.severity="{ item }">
      <InteractionSeverityChip
        :severity="item.details.severity"
      />
    </template>
    <template #item.text="{ item }">
      {{ item.text.en }}
    </template>
    <template #item.details.timing="{ item }">
      <TimingChip
        :timing="item.details.timing"
      />
    </template>
    <template #item.mechanism="{ item }">
      {{ item.mechanism | truncate(30) }}
    </template>
    <template #item.management="{ item }">
      {{ item.management | truncate(30) }}
    </template>
    <template #item.actions="{ item }">
      <v-edit-dialog
        v-if="$auth.check({ drugs: 'edit' })"
        :return-value.sync="item"
        save-text="Update"
        @close="updateDrugInteractions(drugs)"
      >
        <v-btn
          icon
          small
        >
          <v-icon
            x-small
          >
            fal fa-edit
          </v-icon>
        </v-btn>
        <template #input>
          <v-col class="mt-4">
            <v-text-field
              xv-model="item.text.en"
              label="Adverse Reaction"
              dense
            />
            <v-expansion-panels
              flat
              class="mt-n4"
            >
              <v-expansion-panel>
                <v-expansion-panel-header
                  class="ma-0 pa-0 text-caption"
                >
                  Translations
                </v-expansion-panel-header>
                <v-expansion-panel-content class="ma-n5">
                  <language-text-field
                    v-for="language in $languages.filter(l => l.value !== 'en')"
                    :key="language.value"
                    :value.sync="item.text[language.value]"
                    :language="language"
                    :english-text="item.text.en"
                    :label="$t('adverse_reaction', language.value)"
                    flag-size="small"
                    translate-button
                    dense
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-select
              v-model="item.details.severity"
              :items="['severe', 'moderate', 'mild']"
              label="Severity"
            />
            <v-text-field
              v-model="item.details.incidence"
              label="Incidence"
            >
              <template #append>
                <v-checkbox
                  label="Unknown"
                  dense
                  @click="item.details.incidence = ''"
                />
              </template>
            </v-text-field>
            <v-select
              v-model="item.details.timing"
              label="Timing"
              :items="['Rapid', 'Early', 'Delayed']"
            />
          </v-col>
          <v-divider />
          <v-col align="right">
            <v-btn
              color="error"
              icon
              @click="drug.adverse_reactions.splice(drug.adverse_reactions.indexOf(item), 1)"
            >
              <v-icon small>
                fal fa-trash-alt
              </v-icon>
            </v-btn>
          </v-col>
        </template>
      </v-edit-dialog>
    </template>
  </v-data-table>
</template>
<script>



export default {
  components: {
    InteractionSeverityChip: () => import('@/components/drug/InteractionSeverityChip.vue'),
    TimingChip: () => import('@/components/drug/TimingChip.vue'),
    LanguageTextField: () => import('@/components/LanguageTextField.vue'),
  },
  props: {
    drug: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      drugs: [],
      adverse_reactions: [],
    }
  },
  mounted () {
    this.fetchAdverseReactions()
  },
  methods: {
    addItem(name) {
      const newItem = {
        text: { en: name },
        details: {},
      }
      this.drug.adverse_reactions.push(newItem)
      return newItem
    },
    onChange(entry) {
      let adverse_reactions = []
      entry.forEach(reaction => {
        if (typeof reaction === 'string' && reaction.trim()) {
          reaction = {
            text: { en: reaction },
          }
        }
        if (!reaction.details) {
          reaction.details = {}
        }
        adverse_reactions.push(reaction)
      })
      this.$set(this.drug, 'adverse_reactions', adverse_reactions)
    },
    fetchAdverseReactions() {
      this.axios.get(`admin/drugs/adverse_reactions`)
        .then(response => {
          this.adverse_reactions = response.data.map(item => ({
            id: item.id,
            text: item.text,
            details: {},
          }))
        })
        // .catch(error => {
        //   console.error(error)
        // })
    },
  },
}
</script>
